/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { SharedTransition as SourceSharedTransition } from 'SourceComponent/SharedTransition/SharedTransition.component';
import { isSignedIn } from 'Util/Auth';

/** @namespace Satisfly/Component/SharedTransition/Component */
export class SharedTransition extends SourceSharedTransition {
    render() {
        const { state: { sharedElement } } = this.props;

        return (
            <div
              block="SharedTransition"
              mods={ { isVisible: isSignedIn() && !!sharedElement } }
              ref={ this.sharedContainer }
            />
        );
    }
}

export default SharedTransition;
